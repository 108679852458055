import { analyticsProviderConfig, LibAnalyticsProvider, useLibAnalytics } from '@sparx/analytics';
import { useSessionWithAnonymous } from 'api/auth';
import { useSchoolName } from 'api/school';
import { PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

export const AnalyticsProvider = ({ children }: PropsWithChildren) => {
  const { data: { schoolId } = {} } = useSchoolName();
  const { data: session } = useSessionWithAnonymous();

  const sessionID = useMemo(() => getSessionId(), []);

  const config: analyticsProviderConfig = {
    application: 'assessments',
    schoolID: schoolId || 'unknown',
    userID: session ? session.userId : 'anonymous',
    sessionID,
    version: import.meta.env.VITE_RELEASE_VERSION || 'unknown',
    pumps: window.settings?.interactionGatewayUrl
      ? {
          interactionGateway: {
            URL: window.settings.interactionGatewayUrl,
          },
        }
      : {},
    debugLogs: import.meta.env.MODE !== 'production',
  };

  return (
    <LibAnalyticsProvider config={config}>
      <PageViewAnalytics />
      {children}
    </LibAnalyticsProvider>
  );
};

const localStorageSessionIdKey = 'assessments/sessionid';

const getSessionId = () => {
  if (!localStorage) return 'no-local-storage'; // exit early just in case
  let sessionId = localStorage.getItem(localStorageSessionIdKey);
  if (!sessionId) {
    sessionId = uuid();
    localStorage.setItem(localStorageSessionIdKey, sessionId);
  }
  return sessionId;
};

const uuidRegex =
  /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g;

/**
 * PageViewAnalytics sends a page view event containing the old and new url's
 * pathname whenever the url changes.
 **/
const PageViewAnalytics = () => {
  const storedPathname = useRef('uninitialised');
  const { pathname, search } = useLocation();
  const pathnameWithSearch = `${pathname}${search}`;

  const sendEvent = useLibAnalytics();

  useEffect(() => {
    if (pathnameWithSearch !== storedPathname.current) {
      sendEvent({
        action: 'pageview',
        category: 'navigation',
        labels: {
          previousPath: storedPathname.current,
          previousPathGeneric: storedPathname.current.replace(uuidRegex, '<uuid>'),
          path: pathnameWithSearch,
          pathGeneric: pathnameWithSearch.replace(uuidRegex, '<uuid>'),
        },
      });
      storedPathname.current = pathnameWithSearch;
    }
  }, [pathnameWithSearch, sendEvent, storedPathname, uuidRegex]);

  return null;
};
