import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Activity, SkillActivity_State } from '@sparx/api/apis/sparx/packageactivity/v1/activity';
import { TaskItem } from '@sparx/api/apis/sparx/packageactivity/v1/package';
import { useActivityViewNotify, useSteps } from '@sparx/packageactivity';
import { SparxQuestion } from '@sparx/question';
import { useActivityAction, useActivityNotify, useSubmitAnswer } from 'api/activities';
import { Button } from 'components/button/Button';
import { SPARX_QUESTION_COLOURS } from 'utils/activities';

import styles from './ActivityDelivery.module.css';

const QUESTION_RENDERER_FONT_SIZE = '18';

interface ActivityDeliveryProps {
  activity: Activity;
  taskItem?: TaskItem;
  onComplete?: () => Promise<unknown>;
  fontSize?: string;
  submitText?: string;
}

export const ActivityDelivery = ({
  activity,
  onComplete,
  fontSize,
  submitText,
}: ActivityDeliveryProps) => {
  const activityAction = useActivityAction(activity);
  const submitAnswer = useSubmitAnswer(activityAction.mutateAsync, {
    onSuccess: onComplete,
  });
  const isLoading = activityAction.isLoading || submitAnswer.isLoading; // || isLoadingRetry;

  // Ensures we send view/unload events when the activity is mounted/unmounted
  const { mutate: viewActionMutate } = useActivityNotify();
  useActivityViewNotify(viewActionMutate, activity);

  const { skillActivity, input, submitDisabled, activeStep, getStepProps } = useSteps(activity);
  const isOnAnswer = skillActivity?.state === SkillActivity_State.QUESTION_ANSWER;

  const getStep = (i: number) => (
    <SparxQuestion
      {...getStepProps(i)}
      readOnly={isLoading || i !== activeStep || !isOnAnswer}
      colours={SPARX_QUESTION_COLOURS}
      fontSize={fontSize || QUESTION_RENDERER_FONT_SIZE}
      sendAnalyticEvent={
        (action, labels) => console.log(action, labels)
        // sendEvent({ category: 'question', action }, { activityName: activity.name, ...labels })
      }
      gapEvaluations={undefined} // ensure gap evaluations are hidden
      //onPaste={onPaste}
      //getUploadedAsset={uploadedAssetProvider}
      //getAssetUrl={getAssetUrl}
    />
  );

  const buttonProps = { isLoading };

  return (
    <div className={styles.QuestionContainer}>
      {getStep(activeStep)}
      <div className={styles.SubmitContainer}>
        <Button
          onClick={() => submitAnswer.mutate(input)}
          rightIcon={<FontAwesomeIcon icon={faChevronRight} fixedWidth={true} />}
          isDisabled={submitDisabled}
          {...buttonProps}
        >
          {submitText || 'Submit'}
        </Button>
      </div>
    </div>
  );
};
