import {
  faCheck,
  faCopy,
  faQuestionCircle,
  faShuffle,
  faSignOut,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useLibAnalytics } from '@sparx/analytics';
import { useIntercom } from '@sparx/intercom';
import { logout, useSession } from 'api/auth';
import { useSchoolName } from 'api/school';
import { urlKnowledgeBase } from 'app/Intercom';
import { Button } from 'components/button/Button';
import { BackLink } from 'components/header/BackLink';
import { ClassSelect } from 'components/header/ClassSelect';
import { Logo } from 'components/logo/Logo';
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownSeparator,
  MenuButton,
} from 'components/menu/Menu';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { sessionIsSparxStaff } from 'utils/sessions';

import styles from './Header.module.css';

export const TeacherHeader = () => {
  const { data: session } = useSession({ suspense: true });
  const { data: { schoolName } = {} } = useSchoolName();
  const isSparxStaff = sessionIsSparxStaff(session);

  const searchParams = window?.location.search;
  const schoolParam = (searchParams ? '&' : '?') + 'school=' + session?.schoolId;
  const [copied, setCopied] = useState(false);
  const handleCopy: React.MouseEventHandler<HTMLDivElement> = evt => {
    // Prevent menu from closing when clicking copy.
    evt.stopPropagation();
    evt.preventDefault();
    // eslint-disable-next-line compat/compat -- admin only feature
    navigator.clipboard.writeText(window.location.href + schoolParam);
    // Change the button text to indicate success
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  const name = session?.displayName || `${session?.givenName} ${session?.familyName}`;

  return (
    <header className={styles.Header}>
      <BackLink />
      <Link to="/teacher">
        <h1 className={styles.Logo}>
          <Logo colour="light" />
        </h1>
      </Link>
      <ClassSelect />
      <div className={styles.Spacer} />
      <KBButton />
      <div className={styles.Actions}>
        <DropdownMenu.Root modal={false}>
          <DropdownMenu.Trigger asChild>
            <MenuButton>{name}</MenuButton>
          </DropdownMenu.Trigger>
          <DropdownMenuContent>
            <div className={styles.CurrentSchoolBox}>
              <span>Signed into:</span>
              <h2>{schoolName}</h2>
            </div>
            <DropdownSeparator />
            {isSparxStaff && (
              <>
                <DropdownMenuItem
                  icon={faShuffle}
                  onClick={() => {
                    window.location.href = `${window.settings?.selectSchoolUrl}/?app=sparx_assessments&noredirect=1`;
                  }}
                >
                  Switch school
                </DropdownMenuItem>
                <DropdownMenuItem icon={copied ? faCheck : faCopy} onClick={handleCopy}>
                  Copy URL
                </DropdownMenuItem>
                <DropdownSeparator />
              </>
            )}
            <DropdownMenuItem icon={faSignOut} onClick={() => logout()}>
              Sign out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu.Root>
      </div>
    </header>
  );
};

const KBButton = () => {
  const sendEvent = useLibAnalytics();
  const { intercomEnabled, show, showArticle, showSpace, isOpenRef, hide } = useIntercom();

  const props = intercomEnabled
    ? {
        onClick: () => {
          sendEvent({
            category: 'intercom',
            action: 'click_header_button',
            labels: { widgetOpen: isOpenRef.current ? 'true' : 'false' },
          });

          if (isOpenRef.current) {
            // If already open, close the widget
            hide();
            return;
          }

          // Show the home tab
          showSpace('home');
          // There seems to be a bug where sometimes the intercom widget won't show, this timout works around that.
          // It seems to happen when an article link is opened, closed and then the help button clicked. Events show the widget open and instantly close.
          setTimeout(() => {
            if (!isOpenRef.current) {
              show();
            }
          }, 100);
        },
      }
    : {
        target: '_blank',
        as: Link,
        to: urlKnowledgeBase,
      };

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  // automatically open Intercom when we have the ?support query param
  useEffect(() => {
    // Only trigger if we have intercom and are not on the landing page
    if (intercomEnabled && location.pathname !== '/') {
      if (searchParams.has('support')) {
        const article = searchParams.get('support');

        // remove the support param now that we have read it.
        setSearchParams(prev => {
          prev.delete('support');
          return prev;
        });

        if (!article) {
          // just open intercom
          showSpace('home');
        } else {
          const num = Number(article);
          if (!isNaN(num)) {
            // Open the relevant article
            // https://developers.intercom.com/installing-intercom/web/methods/#intercomshowarticle-articleid
            showArticle(num);
          } else {
            // Assume it's the name of a 'space' and open that.
            // https://developers.intercom.com/installing-intercom/web/methods/#intercomshowspace-spacename
            showSpace(article);
          }
        }
      }
    }
  }, [intercomEnabled, location, searchParams, setSearchParams, show, showArticle, showSpace]);

  return (
    <Button leftIcon={<FontAwesomeIcon icon={faQuestionCircle} />} colour="whiteAlpha" {...props}>
      Help &amp; Support
    </Button>
  );
};
