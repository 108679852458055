import {
  faCheck,
  faChevronRight,
  faEllipsisV,
  faHashtag,
  faPause,
  faPlay,
  faQuestionCircle,
  faStopwatch,
  faTimes,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
  PauseState,
  Sitting,
  SittingDeliveryState,
  SittingParticipantState,
  WatchSittingResponse,
} from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import { useSession } from 'api/auth';
import { useAssignmentPackages } from 'api/packages';
import { useSittingParticipantsMap, useUpdateSitting, useWatchSitting } from 'api/sittings';
import { Button } from 'components/button/Button';
import { IconButton } from 'components/button/IconButton';
import { LargeLoadingPage } from 'components/largeloading/LargeLoading';
import { DropdownMenuContent, DropdownMenuItem } from 'components/menu/Menu';
import { PageHeader } from 'components/pageheader/PageHeader';
import { PageContainer } from 'components/pages/PageContainer';
import { SimpleAlert } from 'components/simplealert/SimpleAlert';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { sessionIsGuestTeacher } from 'utils/sessions';
import { formatElapsedTime, getElapsedTimeSeconds } from 'utils/time';
import { StudentTable } from 'views/teacher/sittingview/StudentTable';

import styles from './SittingView.module.css';

export const SittingView = () => {
  const params = useParams();
  const { data: sitting, isLoading } = useWatchSitting(params.sittingId || '', '');

  const { data: session, isLoading: isLoadingSession } = useSession({ suspense: false });
  const isGuestTeacher = sessionIsGuestTeacher(session);

  if (!sitting?.sitting || isLoading || isLoadingSession) {
    return <LargeLoadingPage />;
  }
  return (
    <SittingViewImplementation
      sitting={sitting}
      sittingName={sitting.sitting.sittingName}
      isGuestTeacher={isGuestTeacher}
    />
  );
};

const SittingViewImplementation = ({
  sitting,
  sittingName,
  isGuestTeacher,
}: {
  sitting: WatchSittingResponse;
  sittingName: string;
  isGuestTeacher?: boolean;
}) => {
  const { data: participants, refetch } = useSittingParticipantsMap(sittingName);

  // Trigger a refetch whenever the participants change
  useTriggerParticipantChanges(sitting.participantStates, refetch);

  const { data: packages } = useAssignmentPackages(sittingName);
  const { mutateAsync: updateSitting } = useUpdateSitting(sittingName);

  let controls;
  switch (sitting?.sitting?.state?.state) {
    case SittingDeliveryState.SCHEDULED:
      controls = (
        <Stack spacing={2}>
          <SimpleAlert
            header="How do I get started?"
            body={
              <ol className={styles.GettingStartedList}>
                <li>
                  Students enter this address into their URL bar:{' '}
                  <a href="https://sparxtest.com" target="_blank" rel="noreferrer">
                    sparxtest.com
                  </a>
                </li>
                <li>
                  Students select their school and enter this join code when prompted:{' '}
                  <strong>{sitting.sitting?.joinCode}</strong>
                </li>
                <li>
                  Teacher clicks <strong>Start sitting</strong>
                </li>
              </ol>
            }
            confirmText="Start sitting"
            cancelText="Close"
            onConfirm={() => updateSitting({ oneofKind: 'start', start: {} })}
          >
            {props => (
              <Button
                leftIcon={<FontAwesomeIcon icon={faQuestionCircle} />}
                variant="outline"
                {...props}
              >
                How do I get started?
              </Button>
            )}
          </SimpleAlert>

          <Button onClick={() => updateSitting({ oneofKind: 'start', start: {} })}>
            Start sitting
          </Button>
          <SimpleAlert
            header="Cancel sitting"
            body={<p>Are you sure you want to cancel this sitting?</p>}
            confirmText="Cancel sitting"
            onConfirm={() => updateSitting({ oneofKind: 'end', end: {} })}
          >
            {({ onClick }) => (
              <DropdownMenu.Root modal={false}>
                <DropdownMenu.Trigger asChild>
                  <IconButton
                    icon={<FontAwesomeIcon icon={faEllipsisV} />}
                    aria-label="More"
                    variant="ghost"
                  />
                </DropdownMenu.Trigger>
                <DropdownMenuContent
                  align="end"
                  rounded="all"
                  style={{ marginTop: 'var(--spx-unit-2)' }}
                >
                  <DropdownMenuItem icon={faTimes} onClick={onClick}>
                    Cancel sitting
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu.Root>
            )}
          </SimpleAlert>
        </Stack>
      );
      break;
    case SittingDeliveryState.IN_PROGRESS: {
      const paused = sitting?.sitting?.state?.pauseState === PauseState.PAUSED;
      controls = (
        <Stack spacing={2}>
          <Button
            colour={paused ? 'green' : 'orange'}
            leftIcon={<FontAwesomeIcon fixedWidth={true} icon={paused ? faPlay : faPause} />}
            onClick={() =>
              updateSitting({
                oneofKind: 'updatePause',
                updatePause: {
                  pauseState: paused ? PauseState.ACTIVE : PauseState.PAUSED,
                },
              })
            }
          >
            {paused ? 'Resume' : 'Pause'} sitting
          </Button>
          <SimpleAlert
            header="End sitting"
            body={
              <>
                <p>Are you sure you want to end this sitting?</p>
                {participants?.size === 0 && (
                  <p style={{ marginTop: 'var(--spx-unit-2)' }}>
                    This sitting will be marked as cancelled as no students have joined it.
                  </p>
                )}
              </>
            }
            confirmText="End sitting"
            onConfirm={() => updateSitting({ oneofKind: 'end', end: {} })}
          >
            {props => (
              <Button colour="red" {...props}>
                End sitting
              </Button>
            )}
          </SimpleAlert>
        </Stack>
      );
      break;
    }
    case SittingDeliveryState.COMPLETED: {
      controls = (
        <Stack spacing={6}>
          {!sitting?.sitting?.state?.processedTimestamp ? (
            <Stack spacing={3}>
              <LoadingSpinner />
              <span>Processing answers...</span>
            </Stack>
          ) : isGuestTeacher ? (
            <Stack spacing={3}>
              <FontAwesomeIcon icon={faCheck} />
              <span>Answers processed</span>
            </Stack>
          ) : (
            <Button
              as={Link}
              to={`/teacher/${sitting.sitting?.assessmentName}/match`}
              colour="blue"
              rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
              variant="outline"
            >
              Match students and upload results
            </Button>
          )}
        </Stack>
      );
    }
  }

  const { state } = useLocation();
  const backLink =
    state?.from || `/teacher/sittings${sitting?.sitting?.state?.endTimestamp ? '?tab=ended' : ''}`;

  return (
    <PageContainer>
      <PageHeader back={backLink} right={controls}>
        {sitting.sitting?.title}
      </PageHeader>
      <div className={styles.Content}>
        <section className={styles.Sidebar}>
          <div className={styles.SidebarBlock}>
            <h4>
              <FontAwesomeIcon icon={faHashtag} />
              Join code
            </h4>
            <p className={styles.JoinCode}>{sitting?.sitting?.joinCode}</p>
          </div>
          <div className={styles.SidebarBlock}>
            <h4>
              <FontAwesomeIcon icon={faStopwatch} />
              Time
              {sitting?.sitting?.state?.state === SittingDeliveryState.COMPLETED && (
                <span className={styles.CompletedBadge}>Completed</span>
              )}
              {sitting?.sitting?.state?.state === SittingDeliveryState.CANCELLED && (
                <span className={styles.CancelledBadge}>Cancelled</span>
              )}
            </h4>
            <p>
              <ElapsedTime sitting={sitting.sitting} />
            </p>
          </div>
          <div className={styles.SidebarBlock}>
            <h4>
              <FontAwesomeIcon icon={faUser} />
              Joined
            </h4>
            <p>
              {participants?.size || 0} student{participants?.size === 1 ? '' : 's'}
            </p>
          </div>
        </section>
        <section className={styles.Students}>
          <StudentTable
            sitting={sitting}
            participants={participants}
            packages={packages?.studentPackages || []}
          />
        </section>
      </div>
    </PageContainer>
  );
};

export const ElapsedTime = ({ sitting }: { sitting?: Sitting }) => {
  const [elapsed, setElapsed] = useState(getElapsedTimeSeconds(sitting?.state));

  useEffect(() => {
    const interval = setInterval(() => {
      const newTime = getElapsedTimeSeconds(sitting?.state);
      setElapsed(newTime);
    }, 1000);
    return () => clearInterval(interval);
  }, [sitting, setElapsed]);

  return <>{formatElapsedTime(elapsed)}</>;
};

const useTriggerParticipantChanges = (
  participants: SittingParticipantState[] | undefined,
  onChange: () => void,
) => {
  const [lastParticipants, setLastParticipants] = useState<Set<string>>(
    participantsToSet(participants),
  );
  useEffect(() => {
    const newParticipants = participantsToSet(participants);
    if (!setsEqual(newParticipants, lastParticipants)) {
      onChange();
      setLastParticipants(newParticipants);
    }
  }, [lastParticipants, setLastParticipants, participants, onChange]);
};

const participantsToSet = (participants: SittingParticipantState[] | undefined) => {
  return new Set(participants?.map(p => p.participantSubject) || []);
};

const setsEqual = <T,>(xs: Set<T>, ys: Set<T>) =>
  xs.size === ys.size && [...xs].every(x => ys.has(x));
