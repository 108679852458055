import classNames from 'classnames';
import { motion, useAnimate } from 'framer-motion';
import { FunctionComponent, ReactNode, useEffect } from 'react';

import styles from './ProgressBar.module.css';

export interface IProgressBarProps {
  percentComplete?: number;
  containerClassName?: string;
  completionBarClassName?: string;
  showHighlight?: boolean;
  children?: ReactNode;
  animate?: boolean;
  onBarFillAnimateComplete?: () => void;
}

export const ProgressBar: FunctionComponent<IProgressBarProps> = ({
  percentComplete = 0,
  containerClassName,
  completionBarClassName,
  showHighlight,
  children,
  animate,
  onBarFillAnimateComplete,
}: IProgressBarProps) => {
  const [parentScope, animateParent] = useAnimate();
  const [childScope, animateChild] = useAnimate();

  useEffect(() => {
    const enterAnimation = async () => {
      if (!animate) return;
      animateChild(
        childScope.current,
        { scaleX: [0.5, 1] },
        { duration: 1, delay: 0, ease: 'easeOut' },
      );
      await animateParent(
        parentScope.current,
        { rotate: [0, 1, -1, 1, 0] },
        { duration: 1, delay: 0, ease: 'easeInOut' },
      );
      onBarFillAnimateComplete?.();
      await animateParent(
        parentScope.current,
        { scale: 1 },
        { duration: 0.6, delay: 0, bounce: 0.7, damping: 5, type: 'spring' },
      );
    };
    enterAnimation();
  }, [animateChild, animateParent, childScope, onBarFillAnimateComplete, parentScope, animate]);

  return (
    <motion.div
      ref={parentScope}
      className={classNames(styles.Container, containerClassName)}
      initial={{ scale: 0.9 }}
    >
      <motion.div
        ref={childScope}
        className={classNames(styles.Completion, completionBarClassName)}
        style={{ width: `${percentComplete}%`, originX: 0 }}
      >
        {showHighlight && <div className={styles.Highlight} />}
        {children}
      </motion.div>
    </motion.div>
  );
};
